import {
  CommunityDetailContext,
  CommunityDetailType
} from '@src/utils/context';
import { useContext, useEffect, useState } from 'react';

// Initialises state for use with top level context provider, returns state handler and current context value
export const useCommunityDetailProvider = () => {
  const [communityDetail, setCommunityDetailContext] =
    useState<CommunityDetailType>();

  return { communityDetail, setCommunityDetailContext };
};

// Hook to set community detail context - checks against current context value to avoid unnecessary re-renders
// This is only used in page templates, set context with props
export const useSetCommunityDetail = (
  newCommunityDetail: CommunityDetailType
) => {
  const { communityDetail, setCommunityDetailContext } = useContext(
    CommunityDetailContext
  );

  useEffect(() => {
    setCommunityDetailContext(newCommunityDetail);
  }, [newCommunityDetail, communityDetail, setCommunityDetailContext]);
};

// Hook to get community detail context
export const useCommunityDetail = () => {
  const { communityDetail, setCommunityDetailContext } = useContext(
    CommunityDetailContext
  );

  return { communityDetail, setCommunityDetailContext };
};
