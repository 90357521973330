/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogLogs } from '@datadog/browser-logs';

enum StatusType {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

interface Logger {
  init: () => void;
  log: (
    message: string,
    context?: Record<string, any>,
    status?: StatusType
  ) => void;
  debug: (message: string, context?: Record<string, any>) => void;
  info: (message: string, context?: Record<string, any>) => void;
  warn: (message: string, context?: Record<string, any>) => void;
  error: (message: string, context?: Record<string, any>) => void;
  DEBUG: StatusType.DEBUG;
  INFO: StatusType.INFO;
  WARN: StatusType.WARN;
  ERROR: StatusType.ERROR;
}

const loggingDisabled = ['LOCAL', 'DEV', 'SIT'].includes(
  process.env.NEXT_PUBLIC_ENV || ''
);

const init = (): void => {
  if (loggingDisabled) {
    return;
  }
  datadogLogs.init({
    clientToken:
      process.env.DATADOG_CLIENT_TOKEN ||
      process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ||
      '',
    site: process.env.DATADOG_SITE || process.env.NEXT_PUBLIC_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    silentMultipleInit: true
  });
};

const log = (
  message: string,
  context?: Record<string, any>,
  status?: StatusType
): void => {
  if (loggingDisabled) {
    return;
  }
  typeof context === 'undefined' &&
    typeof status === 'undefined' &&
    datadogLogs.logger.log(message);
  typeof context !== 'undefined' &&
    typeof status === 'undefined' &&
    datadogLogs.logger.log(message, context);
  typeof context !== 'undefined' &&
    typeof status !== 'undefined' &&
    datadogLogs.logger.log(message, context, status);
};

const debug = (message: string, context?: Record<string, any>): void => {
  if (loggingDisabled) {
    return;
  }
  typeof context === 'undefined'
    ? datadogLogs.logger.debug(message)
    : datadogLogs.logger.debug(message, context);
};

const info = (message: string, context?: Record<string, any>): void => {
  if (loggingDisabled) {
    return;
  }
  typeof context === 'undefined'
    ? datadogLogs.logger.info(message)
    : datadogLogs.logger.info(message, context);
};

const warn = (message: string, context?: Record<string, any>): void => {
  if (loggingDisabled) {
    return;
  }
  typeof context === 'undefined'
    ? datadogLogs.logger.warn(message)
    : datadogLogs.logger.warn(message, context);
};

const error = (message: string, context?: Record<string, any>): void => {
  if (loggingDisabled) {
    return;
  }
  typeof context === 'undefined'
    ? datadogLogs.logger.error(message)
    : datadogLogs.logger.error(message, context);
};

const logger: Logger = {
  init: init,
  log: log,
  debug: debug,
  info: info,
  warn: warn,
  error: error,
  INFO: StatusType.INFO,
  DEBUG: StatusType.DEBUG,
  WARN: StatusType.WARN,
  ERROR: StatusType.ERROR
};

export default logger;
