import React, { StrictMode } from 'react';
import { AppProps } from 'next/app';
import logger from '@src/utils/logger';
import '@src/styles/global/index.scss';
import {
  CommunityDetailContext,
  FeatureToggleContext
} from '@src/utils/context';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@src/services/apollo';
import { useStoreUtmParams } from '@src/hooks/useStoreUtmParams';
import Head from 'next/head';
import { useCommunityDetailProvider } from '@src/hooks/useCommunityDetail';
import { useModalFormCallback } from '@src/hooks/useModalFormCallback';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// feature flags
const enabledFeatures = process.env.NEXT_PUBLIC_ENABLED_FEATURES
  ? JSON.parse(process.env.NEXT_PUBLIC_ENABLED_FEATURES)
  : [''];

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  logger.init();

  // set UTM params (if present in URL)
  useStoreUtmParams();
  // form success/error modal
  useModalFormCallback();

  const { communityDetail, setCommunityDetailContext } =
    useCommunityDetailProvider();

  const recaptchaKey: string =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || 'Not defined';

  return (
    <>
      <StrictMode>
        <Head>
          <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
          />
        </Head>
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          <FeatureToggleContext.Provider value={{ enabledFeatures }}>
            <CommunityDetailContext.Provider
              value={{ communityDetail, setCommunityDetailContext }}
            >
              <ApolloProvider client={apolloClient}>
                <Component {...pageProps} />
              </ApolloProvider>
            </CommunityDetailContext.Provider>
          </FeatureToggleContext.Provider>
        </GoogleReCaptchaProvider>
      </StrictMode>
    </>
  );
};

export default App;
