import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { GraphQLErrorExtensions, stripIgnoredCharacters } from 'graphql';
import { setContext } from '@apollo/client/link/context';
import chalk from 'chalk';

// 429 Too Many Requests
// 408 Request timeout
// 502 Bad Gateway (contentful)
// 504 Gateway Timeout (contentful)
// undefined e.g. ERR_ADDRESS_UNREACHABLE, ERR_GET_ADDRESS_INFO - (TCP/IP layer)
// --- possible other codes to retry on ---
// 500 generic error response. Server encountered an unexpected condition that prevented it from fulfilling the request.
// 503 Service Unavailable
const RetryStatusCodes = [429, 408, 502, 504, undefined];
const uri = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`;
const log = console.log;

// GraphQL resource
const resourcelink = new HttpLink({
  uri,
  // strip all whitespaces in GraphQL request https://www.apollographql.com/docs/react/api/link/apollo-link-http/#print
  print: (ast, originalPrint) => stripIgnoredCharacters(originalPrint(ast))
});

// debug logging : This will output the queries that are submitted to the server.
const debugLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    log(
      chalk.green(
        `\r\n============== START | Query:==> ${operation?.operationName} |=======================\r\n`
      )
    );
    log(chalk.green('Query: Source ==>', operation.query.loc?.source.body));
    log(
      chalk.green(
        `\r\n============== END | Query:==> ${operation?.operationName} |=========================\r\n`
      )
    );

    return data;
  });
});

const getContenfulErrorCode = (extensions: GraphQLErrorExtensions) => {
  const contentfulError =
    (extensions?.contentful as GraphQLErrorExtensions) || {};

  return contentfulError.code;
};

// log any GraphQL or network errors that occurred, ignoring UNRESOLVABLE_LINK
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    // filter out UNRESOLVABLE_LINK from graphQLErrors if env var is set
    // NOTE: because errorPolicy is 'ignore' we don't need to do forward(operation)
    const errors =
      process.env.LOG_UNRESOLVABLE_LINK === 'true'
        ? graphQLErrors
        : graphQLErrors.filter(
            (err) =>
              getContenfulErrorCode(err.extensions) !== 'UNRESOLVABLE_LINK'
          );

    for (const { extensions, message, locations, path } of errors) {
      // https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
      const color =
        getContenfulErrorCode(extensions) === 'UNRESOLVABLE_LINK'
          ? 'yellow'
          : 'red';

      log(
        chalk[color](
          `[GraphQL error]:\r\nExtensions: ${JSON.stringify(
            extensions,
            null,
            4
          )}\r\nMessage: ${message}\r\nLocation: ${JSON.stringify(
            locations,
            null,
            4
          )}\r\nPath: ${path}, \nQueryname: ${operation?.operationName}\r\nVariables: ${JSON.stringify(
            operation.variables,
            null,
            4
          )}\r\n-----------------------------\r\n`
        )
      );
    }
  }

  if (networkError) {
    log(chalk.bgRed(`[Network error]: ${networkError}`));
  }
});

// custom config for retry on network/server error: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 20000, // 20 seconds max wait
    jitter: true
  },
  attempts: {
    max: 7,
    retryIf: (error) => {
      log(
        chalk.bgRed(
          `[Network error retry] Retry Status Codes: [${RetryStatusCodes.join(
            ','
          )}] ==> StatusCode: ${error?.statusCode || 'TCP/IP'}`
        )
      );
      return RetryStatusCodes.includes(error?.statusCode);
    }
  }
});

// enables switching authorization headers (delivery / preview)
export const setApolloHeaders = (token?: string) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const links = [errorLink, retryLink, authLink.concat(resourcelink)];

  // enable gql query logging if env var LOG_GQL_QUERIES is set to true
  if (process.env.LOG_GQL_QUERIES === 'true') {
    links.splice(0, 0, debugLink);
  }

  const link = from(links);
  apolloClient.setLink(link);
};

// the client
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // delivery and preview:
  // caching disabled
  // error policy 'ignore': graphQLErrors are ignored (error.graphQLErrors is not populated), and any returned data is cached and rendered as if no errors occurred.
  // errors are logged in errorLink
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    }
  }
});

// initialize the client with delivery authorization headers
setApolloHeaders(process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN);
