import { Maybe, SysFragment } from '@src/graphql/gql-types';

export const ENQUIRY_SUCCESS_URL_PARAMETER = 'enquiry-success';
export const CONTACT_SUCCESS_URL_PARAMETER = 'contact-success';
export const ENQUIRY_ERROR_URL_PARAMETER = 'enquiry-error';
export const CONTACT_ERROR_URL_PARAMETER = 'contact-error';
export const RSVP_SUCCESS_URL_PARAMETER = 'rsvp-success';
export const RSVP_ERROR_URL_PARAMETER = 'rsvp-error';

export const RSVP_LEAD_SOURCE = 'RSVP';
export const LEVANDE_WEBSITE_CHANNEL_OF_ENQUIRY = 'Levande Website';

export enum FORM_SUCCESS_URL_PARAMETER {
  ENQUIRY = 'enquire-success',
  CONTACT = 'contact-success',
  RSVP = 'rsvp-success'
}

export enum FORM_ERROR_URL_PARAMETER {
  ENQUIRY = 'enquire-error',
  CONTACT = 'contact-error',
  RSVP = 'rsvp-error'
}

// types for NationalEnquiryForm dynamic radioSelection and selectBox fields
export type NationalEnquiryDynamicSelectBoxValue = {
  title: string;
  sys: SysFragment;
}[];

export type NationalEnquiryDynamicFormValues = Record<
  string,
  NationalEnquiryDynamicSelectBoxValue
>;

export type CreateLeadFormFields = {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  postCode: string;
  enquiringState: string;
  villagePreference: string;
  enquiringDetails: string;
  enquiryDate: string;
  bookVisit: string;
  optIn: string;
  channelOfEnquiry: string;
  websiteURL: string;
  leadSource: string;
  leadRecordType: string;
  sourceDetail: string;
  liveChatTranscript: string;
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
};

// web-to-lead
export type CommunityContactFormFields = {
  oid: string;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  zip: string;
  '00N2800000JO1Ow': string[]; // enquiring for
  '00N2800000FSySg': number; // on behalf of relationship - hidden field
  book_visit: boolean; // cosmetic field
  '00N2800000JO1Om': number; // book a visit
  terms: boolean; // cosmetic field
  '00N2800000JO1Oz': number; // privacy statement provided
  captcha_settings: string; // salesforce reCaptcha field
};

// web-to-lead
export type CommunityEnquiryFormFields = {
  oid: string;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  zip: string;
  subject: string;
  '00N2800000JO1Ow': string[]; // enquiring for
  '00N2800000FSySg': number; // on behalf of relationship - hidden field
  book_visit: boolean; // cosmetic field
  '00N2800000JO1Om': number; // book a visit
  terms: boolean; // cosmetic field
  '00N2800000JO1Oz': number; // privacy statement provided
  captcha_settings: string; // salesforce reCaptcha field
};

// web-to-case
export type NationalContactFormFields = {
  orgid: string;
  '00N2y000000YMwS': string; // first name
  '00N2y000000YMwT': string; // last name
  email: string;
  phone: string; // phone
  name: string; // hidden field
  subject: string; // hidden field
  '00N0I00000K9TSa': string; // I want to / ask a question / raise a complaint / provide feedback
  '00N0I00000K9TSZ': string; // case sub type
  '00N0I00000K9voR': string; // postcode
  description: string; // details / description
  terms: boolean; // cosmetic field
  '00N0I00000K9TSl': number; // privacy statement provided
  captcha_settings: string; // salesforce reCaptcha field
};

export type CreateCaseFormFields = {
  orgid: string;
  name: string;
  email: string;
  phone: string;
  subject: string;
  description: string;
  state: string;
  location: string;
  type: string;
  // DEV
  '00N1y00000CFsOI': Maybe<string>; // location DEV
  '00N1y00000CFsDK': Maybe<string>; // state DEV
  '00N1y00000CFsOD': Maybe<number>; // privacy statement provided - hidden field DEV
  // SIT
  '00N6D00000FaPdD': Maybe<string>; // location SIT
  '00N6D00000FaPdG': Maybe<string>; // state SIT
  '00N6D00000FaPdE': Maybe<number>; // privacy statement provided - hidden field SIT
  // UAT
  '00N9n000000QNTd': Maybe<string>; // location UAT
  '00N9n000000QNTg': Maybe<string>; // state UAT
  '00N9n000000QNTe': Maybe<number>; // privacy statement provided - hidden field UAT
  // PROD
  '00NMq0000000dgR': Maybe<string>; // location PROD
  '00NMq0000000dgU': Maybe<string>; // state PROD
  '00NMq0000000dgS': Maybe<number>; // privacy statement provided - hidden field PROD
  optIn: number;
  recordType: string;
};

// web-to-lead
export type NationalEnquiryFormFields = {
  oid: string;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  zip: string;
  state: string; // interested in
  // salesforce ProjectId / environment (Web Preferred Project Id)
  '00N6D00000El3ud': Maybe<string>; // SALESFORCE PROJECT ID LOCAL / DEV / SIT
  '00N6D00000FHfdF': Maybe<string>; // SALESFORCE PROJECT ID UAT
  '00N2y000002VTcZ': Maybe<string>; // SALESFORCE PROJECT ID PROD
  // end salesforce ProjectId
  communityPreference: string;
  '00N2800000JO1Ow': string[]; // enquiring for
  '00N2800000FSySg': number; // on behalf of relationship - hidden field
  '00N2800000FSyTC': string; // state interested in - hidden field
  terms: boolean; // cosmetic field
  '00N2800000JO1Oz': number; // privacy statement provided
  captcha_settings: string; // salesforce reCaptcha field
};
