import React from 'react';

export const SuccessModal = {
  heading: 'Enquire',
  error: false,
  content: (
    <p style={{ textAlign: 'center' }} data-testid="enquirySuccessHeader">
      Thank you for submitting an enquiry, we will be in touch for a chat
      shortly.
    </p>
  )
};

export const ErrorModal = {
  heading: 'Error',
  error: true,
  content: (
    <p style={{ textAlign: 'center' }} data-testid="enquiryErrorHeader">
      There seems to be a problem with this page. Please call 1800 72 71 70 or
      try again later.
    </p>
  )
};
