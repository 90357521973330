import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { setSessionStorage } from '@src/services/sessionStorage';
import { UTM_PARAMS } from '@src/constants/utm';

/**
 * Hook to store UTM_PARAMS in sessionStorage
 */
export const useStoreUtmParams = () => {
  const router = useRouter();

  useEffect(() => {
    // get utmParams from router
    const { utm_source, utm_medium, utm_campaign, utm_content } = router.query;
    const utmParams = { utm_source, utm_medium, utm_campaign, utm_content };

    // ensure utmParams values are not undefined
    const hasUtmParams = Object.values(utmParams).every(
      (value) => typeof value !== 'undefined'
    );

    // set UTM_PARAMS in sessionStorage
    if (hasUtmParams) {
      setSessionStorage(
        UTM_PARAMS,
        JSON.stringify({ utm_source, utm_medium, utm_campaign, utm_content })
      );
    }
  }, [router.query]);
};
