import { createContext } from 'react';

// Feature flags
export const FeatureToggleContext = createContext({
  enabledFeatures: [] as string[]
});

FeatureToggleContext.displayName = 'FeatureToggleContext';

// Community detail - set in community templates
export interface CommunityDetailType {
  state: string; // enquiringState - used in CreateLeadForm hidden field
  title: string; // villagePreference - used in CreateLeadForm hidden field
  slug: string;
}

interface CommunityDetailContext {
  communityDetail?: CommunityDetailType;
  setCommunityDetailContext: (state: CommunityDetailType) => void;
}

export const CommunityDetailContext = createContext<CommunityDetailContext>({
  setCommunityDetailContext: () => undefined
});

CommunityDetailContext.displayName = 'CommunityDetailContext';
