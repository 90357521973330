import { useState, useEffect } from 'react';
import { ErrorModal, SuccessModal } from '@src/constants/modal';
import {
  FORM_ERROR_URL_PARAMETER,
  FORM_SUCCESS_URL_PARAMETER
} from '@src/types/forms';
import { useRouter } from 'next/router';
import useModalStore from '@src/store';

/**
 * Hook to trigger form success/error modal if hash param matches success/error const
 */
export const useModalFormCallback = () => {
  const router = useRouter();
  const { openModal } = useModalStore();
  const [hash, setHash] = useState('');

  const updateHash = (str: string) => {
    if (!str) {
      return;
    }
    setHash(str.split('#')[1]);
  };

  // get hash on page load (browser refresh or direct browser URL address navigation)
  useEffect(() => {
    updateHash(router.asPath.match(/#([a-z0-9-]+)/gi)?.[0] || '');
  }, [router.asPath]);

  // get hash on url rewrites
  useEffect(() => {
    const onWindowHashChange = () => updateHash(window.location.hash);
    const onNextJSHashChange = (url: string) => updateHash(url);

    router.events.on('hashChangeStart', onNextJSHashChange);
    window.addEventListener('hashchange', onWindowHashChange);
    window.addEventListener('load', onWindowHashChange);
    return () => {
      router.events.off('hashChangeStart', onNextJSHashChange);
      window.removeEventListener('load', onWindowHashChange);
      window.removeEventListener('hashchange', onWindowHashChange);
    };
  }, [router.asPath, router.events]);

  useEffect(() => {
    if (hash === FORM_SUCCESS_URL_PARAMETER.ENQUIRY) {
      openModal(SuccessModal);
    }
    if (hash === FORM_ERROR_URL_PARAMETER.ENQUIRY) {
      openModal(ErrorModal);
    }
    if (hash === FORM_ERROR_URL_PARAMETER.CONTACT) {
      openModal(ErrorModal);
    }
  }, [hash, openModal]);
};
