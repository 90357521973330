/**
 * set key/value in sessionStorage
 * @param key
 * @param value
 */
export const setSessionStorage = (key: string, value: string) => {
  if (typeof sessionStorage !== 'undefined' && key && value) {
    sessionStorage.setItem(key, value);
  }
};

/**
 * retrieve value by key from sessionStorage
 * @param key
 * @returns
 */
export const getSessionStorage = (key: string) => {
  const item =
    typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(key) : null;

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

/**
 * retrieve key/value from sessionStorage
 * @param key
 */
export const removeSessionStorage = (key: string) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem(key);
  }
};
